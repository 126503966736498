import logo from './logo.svg';
import './App.css';
import LuckySpinCustom from './lucky';
import LuckySpinWithImages from './lucky2';
import Confetti from 'react-confetti'


function App() {
  return (
    <div className="App">
    <LuckySpinCustom/>
   {/* <LuckySpinWithImages/> */}
    </div>
  );
}

export default App;
